<template>
  <div class="fill-height">
    <Description @next="toInfo" v-if="step === 0" />
    <Information @submit="getData" v-if="step === 1" />
    <Schedule ref="schedule" @submit="submit" v-if="step === 2" />
  </div>
</template>
<script>
import liff from "@line/liff";
import Description from "@/components/mobile/reserve/description";
import Information from "@/components/mobile/reserve/information";
import Schedule from "@/components/mobile/reserve/schedule";
import lineMessage from "@/commons/templates/reserveMessage";

export default {
  name: "MobileReserve",
  components: {
    Description,
    Information,
    Schedule,
  },
  data: () => ({
    step: 0,
    data: {},
    //
  }),
  mounted() {},
  methods: {
    toInfo() {
      this.step = this.step + 1;
    },
    getData(val) {
      this.data = val;
      this.step = this.step + 1;
    },
    submit(date) {
      liff.init({ liffId: "1656147464-Py61LVJN" }).then(() => {
        liff.getProfile().then((profile) => {
          let postdata = {
            name: this.data.name,
            phone: this.data.phone,
            county: this.data.county,
            district: this.data.district,
            address: this.data.address,
            displayName: profile.displayName,
            userId: profile.userId,
            date: date,
            service: JSON.stringify(this.data.service),
          };
          let messagedata = {
            name: this.data.name,
            phone: this.data.phone,
            county: this.data.county,
            district: this.data.district,
            address: this.data.address,
            date: this.$date(date).format("YYYY-MM-DD"),
            service: this.data.service.service,
          };
          let content = new lineMessage(messagedata);
          this.$http.post("/reserve", postdata).then((response) => {
            if (response.data.success) {
              liff.sendMessages([content.exportMessage()]).then(() => {
                liff.closeWindow();
              });
            } else {
              this.$refs.schedule.init();
            }
          });
        });
      });
    },
  },
};
</script>
