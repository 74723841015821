var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%"}},[(_vm.loading === false)?_c('div',{staticClass:"d-flex flex-column justify-space-between fill-height"},[_c('div',[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"font-weight-black text-center"},[_vm._v("預約詳細資料")])])],1),_c('v-row',{staticClass:"mx-5 my-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"姓名"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"電話"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"縣市","items":_vm.countylist},model:{value:(_vm.selected.county),callback:function ($$v) {_vm.$set(_vm.selected, "county", $$v)},expression:"selected.county"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"區域","items":_vm.districtlist},model:{value:(_vm.selected.district),callback:function ($$v) {_vm.$set(_vm.selected, "district", $$v)},expression:"selected.district"}})],1)],1),_c('v-text-field',{attrs:{"label":"地址"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1),_c('v-row',{staticClass:"mx-5 my-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("類型")]),_c('v-item-group',{attrs:{"active-class":"primary"},model:{value:(_vm.selected.type),callback:function ($$v) {_vm.$set(_vm.selected, "type", $$v)},expression:"selected.type"}},_vm._l((_vm.getAvailablelist(_vm.datalist.types)),function(item){return _c('v-item',{key:item.sort,staticClass:"mr-2 mt-2",attrs:{"value":item.content},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"input-value":active,"depressed":"","rounded":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(item.content)+" ")])]}}],null,true)})}),1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("施工地點狀況")]),_c('div',{staticClass:"d-flex"},_vm._l((_vm.getAvailablelist(_vm.datalist.locationStatuses)),function(item){return _c('v-checkbox',{key:item.sort,staticClass:"my-0 mr-3",attrs:{"label":item.content,"value":item.content},model:{value:(_vm.selected.locationStatuses),callback:function ($$v) {_vm.$set(_vm.selected, "locationStatuses", $$v)},expression:"selected.locationStatuses"}})}),1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("項目")]),_c('v-item-group',{staticClass:"mt-1",attrs:{"active-class":"primary"},on:{"change":_vm.selectService},model:{value:(_vm.selected.service),callback:function ($$v) {_vm.$set(_vm.selected, "service", $$v)},expression:"selected.service"}},_vm._l((_vm.getAvailablelist(_vm.datalist.services)),function(item){return _c('v-item',{key:item.sort,staticClass:"mt-2 mr-2",attrs:{"value":item.content},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"input-value":active,"depressed":"","rounded":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(item.content)+" ")])]}}],null,true)})}),1)],1),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.servicelist),function(item,index){return _c('div',{key:item.sort},[_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(item.title))]),(item.type === 0)?_c('v-radio-group',{staticClass:"mt-0",model:{value:(_vm.selected.servicelist[index]),callback:function ($$v) {_vm.$set(_vm.selected.servicelist, index, $$v)},expression:"selected.servicelist[index]"}},_vm._l((item.options),function(option){return _c('v-radio',{key:option.sort,attrs:{"label":option.content,"value":option.content}})}),1):_c('div',{staticClass:"form-check form-check-inline"},_vm._l((item.options),function(option){return _c('v-checkbox',{key:option.sort,staticClass:"my-0",attrs:{"label":option.content,"value":option.content},model:{value:(_vm.selected.servicelist[index]),callback:function ($$v) {_vm.$set(_vm.selected.servicelist, index, $$v)},expression:"selected.servicelist[index]"}})}),1)],1)}),0)],1)],1),_c('v-footer',{staticClass:"pb-5 mx-5",attrs:{"color":"white"}},[_c('v-btn',{attrs:{"rounded":"","color":"primary","dark":"","block":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" 預約日期 ")])],1)],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v(" 關閉 ")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"width":14,"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.initConfirm),callback:function ($$v) {_vm.initConfirm=$$v},expression:"initConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" 是否要帶入前次報價資料？ ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.insertData()}}},[_vm._v(" 是 ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.initConfirm = false}}},[_vm._v(" 否 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }