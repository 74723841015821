<template>
  <v-container style="height: 100%">
    <div v-if="loading === false" class="d-flex flex-column justify-space-between fill-height">
      <div>
        <v-row>
          <v-col>
            <h2 class="font-weight-black text-center">預約詳細資料</h2>
          </v-col>
        </v-row>
        <v-row class="mx-5 my-5">
          <v-col cols="12">
            <v-text-field label="姓名" v-model="name"></v-text-field>
            <v-text-field label="電話" v-model="phone"></v-text-field>
            <v-row>
              <v-col> <v-select label="縣市" :items="countylist" v-model="selected.county"></v-select></v-col>
              <v-col> <v-select label="區域" :items="districtlist" v-model="selected.district"></v-select></v-col>
            </v-row>
            <v-text-field label="地址" v-model="address"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-5 my-5">
          <v-col cols="12">
            <h4>類型</h4>
            <v-item-group active-class="primary" v-model="selected.type">
              <v-item class="mr-2 mt-2" v-slot="{ active, toggle }" v-for="item in getAvailablelist(datalist.types)" :key="item.sort" :value="item.content">
                <v-btn :input-value="active" depressed rounded @click="toggle"> {{ item.content }} </v-btn>
              </v-item>
            </v-item-group>
          </v-col>
          <v-col cols="12">
            <h4>施工地點狀況</h4>
            <div class="d-flex">
              <v-checkbox :label="item.content" class="my-0 mr-3" v-for="item in getAvailablelist(datalist.locationStatuses)" :key="item.sort" v-model="selected.locationStatuses" :value="item.content"> </v-checkbox>
            </div>
          </v-col>

          <v-col cols="12">
            <h4>項目</h4>
            <v-item-group class="mt-1" active-class="primary" v-model="selected.service" @change="selectService">
              <v-item class="mt-2 mr-2" v-slot="{ active, toggle }" v-for="item in getAvailablelist(datalist.services)" :key="item.sort" :value="item.content">
                <v-btn :input-value="active" depressed rounded @click="toggle"> {{ item.content }} </v-btn>
              </v-item>
            </v-item-group>
          </v-col>

          <v-col cols="12">
            <div v-for="(item, index) in servicelist" :key="item.sort">
              <h4 class="mt-4">{{ item.title }}</h4>
              <v-radio-group class="mt-0" v-if="item.type === 0" v-model="selected.servicelist[index]">
                <v-radio v-for="option in item.options" :key="option.sort" :label="option.content" :value="option.content"> </v-radio>
              </v-radio-group>
              <div class="form-check form-check-inline" v-else>
                <v-checkbox class="my-0" v-for="option in item.options" :key="option.sort" :label="option.content" :value="option.content" v-model="selected.servicelist[index]"> </v-checkbox>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-footer color="white" class="pb-5 mx-5">
        <v-btn rounded color="primary" dark block @click="submit()"> 預約日期 </v-btn>
      </v-footer>
    </div>
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.show = false"> 關閉 </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular :width="14" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="initConfirm" persistent>
      <v-card>
        <v-card-title class="headline"> 是否要帶入前次報價資料？ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="insertData()"> 是 </v-btn>
          <v-btn color="green darken-1" text @click="initConfirm = false"> 否 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import liff from "@line/liff";

export default {
  name: "MobileReserveInformation",
  data: () => ({
    snackbar: {
      show: false,
      message: "",
    },

    loading: false,

    datalist: {
      types: [],
      locationStatuses: [],
      counties: [],
      services: [],
    },

    selected: {
      type: "",
      locationStatuses: [],
      county: "",
      district: "",
      service: "",
      servicelist: [],
    },

    initConfirm: false,
    lastData: null,

    name: "",
    phone: "",
    address: "",
  }),
  computed: {
    servicelist() {
      if (this.selected.service && this.datalist.services.length > 0) {
        let result = this.datalist.services.find((p) => p.content === this.selected.service);
        return result.items;
      } else {
        return [];
      }
    },
    countylist() {
      let list = this.getAvailablelist(this.datalist.counties);
      return list.map((p) => p.content);
    },
    districtlist() {
      let county = this.datalist.counties.find((p) => p.content === this.selected.county);
      return county ? this.getAvailablelist(county.districts).map((p) => p.content) : [];
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    validate() {
      if (!this.name) {
        this.showSnackbar("請輸入名稱");
        return false;
      }

      if (!this.phone) {
        this.showSnackbar("請輸入電話");
        return false;
      }

      if (!this.selected.type) {
        this.showSnackbar("請選擇類型");
        return false;
      }

      if (!this.selected.county) {
        this.showSnackbar("請選擇縣市");
        return false;
      }

      if (!this.selected.district) {
        this.showSnackbar("請選擇地區");
        return false;
      }

      if (!this.address) {
        this.showSnackbar("請輸入地址");
        return false;
      }

      if (!this.selected.service) {
        this.showSnackbar("請選擇要估價之服務");
        return false;
      }

      return true;
    },
    selectService(val) {
      let service = this.datalist.services.find((p) => p.content === val);
      this.selected.servicelist = [];
      service.items.forEach((item) => {
        if (item.type === 0) this.selected.servicelist.push("");
        if (item.type === 1) this.selected.servicelist.push([]);
      });
    },
    submit() {
      if (!this.validate()) return;

      let service = {
        type: this.selected.type,
        locationStatuses: this.selected.locationStatuses,
        service: this.selected.service,
        content: this.servicelist.map((item, index) => ({
          title: item.title,
          type: item.type,
          value: this.selected.servicelist[index],
        })),
      };
      this.$emit("submit", {
        name: this.name,
        phone: this.phone,
        address: this.address,
        county: this.selected.county,
        district: this.selected.district,
        service: service,
      });
      this.$emit("next");
    },
    async init() {
      await this.getServices();
      await this.getCounties();
      await this.getStatus();
      this.initInfo();
      this.loading = false;
    },
    async getServices() {
      await this.$http.get("/service").then((response) => {
        if (response.data.success) {
          this.datalist.services = response.data.result;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    async getCounties() {
      await this.$http.get("/service/county").then((response) => {
        if (response.data.success) {
          this.datalist.counties = response.data.result;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    async getStatus() {
      await this.$http.get("/service/status").then((response) => {
        if (response.data.success) {
          this.datalist.types = response.data.result.types;
          this.datalist.locationStatuses = response.data.result.locationStatuses;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    showSnackbar(val) {
      this.snackbar.message = val;
      this.snackbar.show = true;
    },
    getAvailablelist(array) {
      return array.filter((p) => p.enable);
    },
    initInfo() {
      liff.init({ liffId: "1656147464-Py61LVJN" }).then(() => {
        liff.getProfile().then((profile) => {
          this.$http.get("/quote/last", { params: { userId: profile.userId } }).then((response) => {
            if (response.data.success) {
              this.lastData = response.data.result;
              this.initConfirm = true;
            }
          });
        });
      });
    },
    insertData() {
      let data = this.lastData;
      this.initConfirm = false;

      // 取得區域
      if (this.countylist.includes(data.county)) {
        this.selected.county = data.county;

        if (this.districtlist.includes(data.district)) {
          this.selected.district = data.district;
          this.address = data.street;
        }
      }

      let service = JSON.parse(data.service);

      // 取得類型
      let typelsit = this.getAvailablelist(this.datalist.types).map((p) => p.content);
      if (typelsit.includes(service.type)) {
        this.selected.type = service.type;
      }

      // 取得施工地點狀況
      let statuslist = this.getAvailablelist(this.datalist.locationStatuses).map((p) => p.content);
      service.locationStatuses.forEach((item) => {
        if (statuslist.includes(item)) {
          this.selected.locationStatuses.push(item);
        }
      });

      // 取得服務內容
      let servicelsit = this.getAvailablelist(this.datalist.services).map((p) => p.content);
      if (servicelsit.includes(service.service)) {
        this.selected.service = service.service;

        service.content.forEach((item) => {
          let block = this.servicelist.find((p) => p.title === item.title && p.type === item.type);
          if (block) {
            let index = this.servicelist.findIndex((p) => p.sort === block.sort);
            this.selected.servicelist[index] = item.value;
          }
        });
      }
    },
  },
};
</script>

<style>
.v-input .v-messages {
  min-height: 0;
}

.v-input__control .v-input__slot {
  margin-bottom: 0;
}
</style>