<template>
  <v-container style="height: 100%">
    <div v-if="loading === false" class="d-flex flex-column justify-space-between fill-height">
      <div>
        <v-row>
          <v-col>
            <h2 class="font-weight-black text-center">選擇預約日期</h2>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col>
            <date-picker is-expanded v-model="selected" :min-date="mindate" :max-date="maxdate" :disabled-dates="dateInvaild" />
          </v-col>
        </v-row>
        <v-row class="mx-5" v-if="dateSelected">
          <v-col>
            <v-card>
              <v-card-title>預約狀況：{{ $date(dateSelected.date).format("YYYY-MM-DD") }}</v-card-title>
              <v-card-text class="font-weight-bold">
                <v-alert type="success">可以預約（剩餘 {{ dateSelected.availbale }} 可預約）</v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-footer color="white" class="pb-5 mx-5">
        <v-btn rounded color="success" dark block @click="submit()"> 確認預約 </v-btn>
      </v-footer>
    </div>
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.show = false"> 關閉 </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular :width="14" indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  name: "MobileReserveSchedule",
  components: {
    DatePicker,
  },
  data: () => ({
    snackbar: {
      show: false,
      message: "",
    },
    loading: true,
    selected: null,
    mindate: null,
    maxdate: null,
    disableddate: [],
    reserves: [],
    limit: 1,
    rules: [],
  }),
  computed: {
    dateSelected() {
      if (!this.selected) return null;
      let data = this.dateStatus.find((p) => p.date === this.$date(this.selected).startOf("day").format());
      return data;
    },
    dateInvaild() {
      let invail = this.dateStatus.filter((p) => p.availbale < 1);
      return invail.map((p) => {
        return {
          start: this.$date(p.date).toDate(),
          end: this.$date(p.date).toDate(),
        };
      });
    },
    dateStatus() {
      let result = [];
      let days = this.$date(this.maxdate).diff(this.mindate, "day") + 1;
      let dates = [...Array(days).keys()];
      dates.forEach((item) => {
        let date = this.$date(this.mindate).add(item, "day");
        let limit = this.limit;
        let weekrule = this.rules.find((p) => p.week != null && this.$date(date).day() === p.week);
        if (weekrule) limit = weekrule.limit;
        let daterule = this.rules.find((p) => p.date != null && this.$date(p.date).format() === date.format());
        if (daterule) limit = daterule.limit;

        let reserves = this.reserves.filter((p) => this.$date(p).startOf("day").format() === date.format());
        let availbale = limit - reserves.length;

        result.push({
          date: date.format(),
          availbale: availbale,
        });
      });

      return result;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    showSnackbar(val) {
      this.snackbar.message = val;
      this.snackbar.show = true;
    },
    init() {
      this.loading = true;
      this.selected = null;
      this.$http.get("/reserve/info").then((response) => {
        if (response.data.success) {
          this.mindate = this.$date(response.data.result.startDate).toDate();
          this.maxdate = this.$date(response.data.result.endDate).toDate();
          this.reserves = response.data.result.reserves;
          this.limit = response.data.result.limit;
          this.rules = response.data.result.rules;
        }
        this.loading = false;
      });
    },
    submit() {
      if (!this.dateSelected) {
        this.showSnackbar("請選擇可預約日期");
        return false;
      }
      this.loading = true;
      this.$emit("submit", this.dateSelected.date);
    },
  },
};
</script>
