<template>
  <v-container style="height: 100%">
    <div v-if="loading === false" class="d-flex flex-column justify-space-between fill-height">
      <div>
        <v-row>
          <v-col>
            <h2 class="font-weight-black text-center">預約說明</h2>
          </v-col>
        </v-row>
        <v-row class="mx-5 my-5">
          <v-col>
            <div class="body-1" v-html="content"></div>
          </v-col>
        </v-row>
      </div>
      <v-footer color="white" class="pb-5 mx-5">
        <v-btn rounded color="primary" dark block @click="$emit('next')"> 進入預約 </v-btn>
      </v-footer>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular :width="14" indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "MobileQuoteDescription",
  data: () => ({
    content: "",
    loading: true,
  }),
  mounted() {
    this.getDescription();
  },
  methods: {
    getDescription() {
      this.$http.get("/reserve/description").then((response) => {
        if (response.data.success) {
          this.content = response.data.result;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
  },
};
</script>
