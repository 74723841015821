export default class {
    constructor(data) {
        this.name = data.name;
        this.phone = data.phone;
        this.district = data.district;
        this.county = data.county;
        this.address = data.address;
        this.date = data.date;
        this.service = data.service;
    }
    exportMessage() {
        let result = {
            type: "flex",
            altText: "預約單",
            contents: {
                type: "bubble",
                body: {
                    type: "box",
                    layout: "vertical",
                    contents: [
                        {
                            type: "text",
                            text: this.date + " - 預約單",
                            weight: "bold",
                            color: "#1DB446",
                            size: "sm",
                        },
                        {
                            type: "text",
                            text: this.name,
                            weight: "bold",
                            size: "lg",
                            margin: "md",
                        },
                        {
                            type: "text",
                            text: this.phone,
                            size: "sm",
                            color: "#aaaaaa",
                            wrap: true,
                        },
                        {
                            type: "text",
                            text: `${this.county} ${this.district} ${this.address}`,
                            size: "sm",
                            color: "#aaaaaa",
                            wrap: true,
                        },
                        {
                            type: "separator",
                            margin: "md",
                        },
                        {
                            type: "text",
                            text: this.service,
                            weight: "bold",
                            size: "md",
                            margin: "md",
                        },
                        {
                            type: "separator",
                            margin: "md",
                        },
                        {
                            type: "box",
                            layout: "vertical",
                            margin: "md",
                            spacing: "sm",
                            contents: [
                                {
                                    type: "text",
                                    text: "感謝您的預約！",
                                    size: "md",
                                    color: "#444444",
                                    margin: "lg",
                                    weight: "bold",
                                    wrap: true,
                                },
                            ],
                        },
                    ],
                },
                styles: {
                    footer: {
                        separator: true,
                    },
                },
            },
        };
        return result;
    }
}